import React from "react";
import { Link, useNavigate } from "react-router-dom";
import schoolLogo from "../images/school-logo.png";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

function NavBar() {
  const navigate = useNavigate();
  const store = useSelector((store) => store);
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("student");
    dispatch({ type: "logout" });
    navigate("/login");
  };

  return (
    <nav>
      <div className="navbar-inner-container">
        <div className="school-logo">
          <Link to="/">
            <img src={schoolLogo} alt="school logo" />
          </Link>
        </div>

        <ul className="navlinks">
          <li>
            <Link
              to="/"
              style={{ color: "rgb(12, 72, 202)", textDecoration: "underline" }}
            >
              Home
            </Link>
          </li>

          {store.login && (
            <li>
              <Link to="">Courses</Link>
              <span>
                <MdArrowDropDown />
              </span>
              <div className="drop-down">
                <ul>
                  <li>
                    <Link to="/viewcourse">View/Edit Courses</Link>
                  </li>
                  <li>
                    <Link to="registercourse">Register Courses</Link>
                  </li>
                </ul>
              </div>
            </li>
          )}

          <li>
            <Link to="/exams">Exams</Link>
          </li>

          {store.login && (
            <li>
              <Link to="/viewprofile">View Profile</Link>
            </li>
          )}

          {/* {store.login && ( */}
          <li className="logout-navlink" onClick={logout}>
            <Link to="">{store.login ? "Logout" : "LogIn"}</Link>
          </li>
          {/* )} */}
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
