import React from 'react'
import Banner from './Banner';
import Features from './Features';


function Home() {
  return (
    <div className="home">
      <Banner />
      <Features />
    </div>
  );
}

export default Home