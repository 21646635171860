import React from "react";
import { Navigate } from "react-router-dom";

/*
    ========= Protected Registration & Login Page =======

    Here is a RegLog protectedRoute which prevent
    user from accessing the website signup and 
    login page after successful login, it's the exact
    opposite of the protectedRoute which prevent
    users from accessing certain web pages before
    login .
    
    ~EOF
*/

function ProtectRegLog({ children }) {
  const user = localStorage.getItem("student");

  if (!user) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}

export default ProtectRegLog;
