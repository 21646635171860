import React, { useState } from "react";
import schoolLogo from "../images/school-logo.png";
import loginBanner from "../images/login-bg.jpg";
import axios from "axios";
import { json, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Login() {
  const [student, setStudent] = useState({
    matricNo: "",
    password: "",
  });
  const { matricNo, password } = student;

  const [error, setError] = useState({ text: "", color: "red" });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //  handleInput
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setStudent({ ...student, [key]: value });
  };

  // handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (matricNo === "" || password === "") {
      setError({ ...error, text: "*Fields cannot be empty" });
    } else {
      setStudent({
        matricNo,
        password,
      });
      const response = await axios.post(
        `http://localhost:5000/api/students/login`,
        student
      );
      if (response.data.msg !== "successfully logged in") {
        setError({ text: response.data.msg, color: "red" });
      } else {
        setError({ text: response.data.msg, color: "green" });
        localStorage.setItem("student", JSON.stringify(response.data));

        setTimeout(() => {
          navigate("/");
        }, 1000);
        dispatch({ type: "login" });
      }
    }
  };

  // Hide/show Password
  const showPasswordFunc = () => {
    if (password.length > 0) {
      setShowPassword(!showPassword);
      showPassword ? setPasswordType("password") : setPasswordType("text");
    }
  };

  return (
    <div className="login">
      <div className="login-wrapper">
        <div className="school-logo-login">
          <img src={schoolLogo} alt="School Logo" />
        </div>
        <div className="form-area-container">
          <h5
            style={{
              textAlign: "center",
              fontStyle: "italic",
              color: error.color,
            }}
          >
            {error.text}
          </h5>
          <form className="login-form" onSubmit={handleSubmit}>
            <div style={{ marginBottom: "1.5em" }}>
              <label>MATRIC NO</label>
              <input name="matricNo" type="text" onChange={handleInput} />
            </div>

            <div className="password">
              <label>Password</label>
              <input
                name="password"
                type={passwordType}
                onChange={handleInput}
              />
              <p onClick={showPasswordFunc}>
                {showPassword === true ? <FaRegEyeSlash /> : <FaRegEye />}
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <button className="login-btn" type="submit">
                Login
              </button>
            </div>
          </form>
          <div className="goto-register">
            <p>
              Don't have an account ?{" "}
              <button>
                <Link to="/register">Click here</Link>
              </button>
              to register
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
