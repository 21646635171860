import React from "react";
import { Link } from "react-router-dom";
import NoPageFound from "../components/images/pagenotfoundnobg.png";

function NoMatchRoutes() {
  return (
    <div className="no-match-routes">
      <div className="content-container">
        <img src={NoPageFound} alt="No Page Found" className="no-route-imag" />
        <button className="go-to-home-btn">
          <Link to="">Go to Home</Link>{" "}
        </button>
      </div>
    </div>
  );
}

export default NoMatchRoutes;
