import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "../../Misc/Modal";

function UpdateSingleStudent() {
  const [student, setStudent] = useState({
    fullName: "",
    matricNo: "",
    address: "",
    state: "",
    email: "",
    phoneNo: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [enableUpdateBtn, setEnableUpdateBtn] = useState(true);

  const { id } = useParams();

  //   handleInput
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setStudent({ ...student, [key]: value });
    showModal && setShowModal(false); //during user entry, set modal to false if previous state is true
    setEnableUpdateBtn(false);
  };

  //   fetchSingleStudent
  const fetchSingleStudent = async () => {
    const response = await axios.get(
      `http://localhost:5000/api/students/${id}`
    );
    setStudent(response.data);
    console.log(student);
  };

  useEffect(() => {
    fetchSingleStudent();
  }, []);

  //   handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.put(`http://localhost:5000/api/students/${id}`, student);
    setShowModal(true);
  };

  return (
    <div className="update-single-student">
      {showModal && (
        <Modal modalText="Profile Successfully updated" showLogin={false} />
      )}
      <div className="update-single-student-form-wrapper">
        <form onSubmit={handleSubmit} className="update-form">
          <div>
            <input
              name="fullName"
              value={student.fullName}
              placeholder="fullName"
              onChange={handleInput}
            />
          </div>

          <div>
            <input
              name="matricNo"
              value={student.matricNo}
              placeholder="matricNo"
              onChange={handleInput}
              disabled
              style={{ cursor: "not-allowed" }}
            />
          </div>

          <div>
            <input
              name="address"
              value={student.address}
              placeholder="address"
              onChange={handleInput}
            />
          </div>

          <div>
            <input
              name="state"
              value={student.state}
              placeholder="state"
              onChange={handleInput}
            />
          </div>

          <div>
            <input
              name="email"
              value={student.email}
              placeholder="email"
              onChange={handleInput}
            />
          </div>

          <div>
            <input
              name="phoneNo"
              value={student.phoneNo}
              placeholder="phone No"
              onChange={handleInput}
            />
          </div>

          <div
            className="update-student_password-field"
            style={{ position: "relative" }}
          >
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={student.password}
              placeholder="password"
              onChange={handleInput}
            />
            <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? "Hide" : "Show"}
            </span>
          </div>

          <button
            type="submit"
            className="update-btn"
            disabled={enableUpdateBtn}
          >
            Update My Profile
          </button>
        </form>
      </div>
    </div>
  );
}

export default UpdateSingleStudent;
