import React from "react";
// router modules
import { BrowserRouter, Routes, Route } from "react-router-dom";
// App Components
import Home from "./components/pages/Home";
import Login from "./components/forms/Login";
import Register from "./components/forms/Register";
import RegisterCourse from "./components/pages/RegisterCourse";
import ViewCourse from "./components/pages/ViewCourse";
import ViewProfile from "./components/pages/ViewProfile";
import Exams from "./components/pages/Exams";
// Custom CSS
import "./components/Styles.css";
// Navbar
import NavBar from "./components/Inc/NavBar";
//protected routes
import ProtectedRoutes from "./components/protectedRoute/ProtectedRoutes";
// reducer
import { createStore } from "redux";
import Reducer from "./components/reducer/Reducer";
import { Provider } from "react-redux";
import Misc from "./components/pages/Misc";
import UpdateSingleStudent from "./components/pages/UpdateSingleStudent";
import NoMatchRoutes from "./Misc/NoMatchRoutes";
import ProtectRegLog from "./components/protectedRoute/ProtectRegLog";

function App() {
  //create store
  const store = createStore(Reducer);

  // const getUserMedium = navigator.userAgent;
  // console.log(getUserMedium.toLowerCase().includes("android"));

  return (
    <BrowserRouter>
      <div>
        <Provider store={store}>
          <NavBar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="login"
              element={
                <ProtectRegLog>
                  <Login />
                </ProtectRegLog>
              }
            />
            <Route
              path="register"
              element={
                <ProtectRegLog>
                  <Register />
                </ProtectRegLog>
              }
            />
            <Route
              path="viewcourse"
              element={
                <ProtectedRoutes>
                  <ViewCourse />
                </ProtectedRoutes>
              }
            />
            <Route
              path="registercourse"
              element={
                <ProtectedRoutes>
                  <RegisterCourse />
                </ProtectedRoutes>
              }
            />
            <Route path="misc" element={<Misc />} />
            <Route
              path="exams"
              element={
                <ProtectedRoutes>
                  <Exams />
                </ProtectedRoutes>
              }
            />
            <Route
              path="viewprofile"
              element={
                <ProtectedRoutes>
                  <ViewProfile />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/updatesinglestudent/:id"
              element={<UpdateSingleStudent />}
            />
            <Route path="*" element={<NoMatchRoutes />} />
          </Routes>
        </Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
