import React from "react";
import { json, Link } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi";
// import studentPhoto from "../images/home-banner.jpg";

function ViewProfile() {
  const studentObj = JSON.parse(localStorage.getItem("student"));
  // const studentKeys = Object.keys(studentObj);

  let mapResult;

  return (
    <div className="view-profile">
      <div className="profile-container">
        <div className="profile-page-title">
          <h4>Student Profile</h4>
        </div>

        <div className="profile-photo-wrapper">
          {/* <img src={studentPhoto} alt="" /> */}
    
          {<HiUserCircle style={{ height: "150px", width: "150px", color:"rgba(255,255,255,0.7)" }} />}
        </div>
        <div className="student-profile-wrapper">
          {
            (mapResult = Object.keys(studentObj).map(
              (singleStudentKey, index) => {
                return (
                  singleStudentKey !== "msg" &&
                  singleStudentKey !== "id" && (
                    <div className="field-wrapper" key={index}>
                      <label className="profile-label">
                        {singleStudentKey.slice(0, 1).toUpperCase() +
                          singleStudentKey.slice(1)}
                      </label>
                      <p className="profile-value">
                        {studentObj[singleStudentKey]}
                      </p>
                    </div>
                  )
                );
              }
            ))
          }
          <button className="update-profile-btn">
            <Link to={`/updatesinglestudent/${studentObj.id}`}>
              Update Profile
            </Link>{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewProfile;
