import React from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { GiSportMedal } from "react-icons/gi";
import { RiUserHeartFill } from "react-icons/ri";

function Features() {
  return (
    <div className="features-card">
      <div className="single-card odd">
        <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
          <FaChalkboardTeacher />
        </div>
        <h3>Certified Teachers</h3>
        <p>
          Even the all-powerful Pointing has no control about the blind texts it
          is an almost unorthographic.
        </p>
      </div>

      <div className="single-card even">
        <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
          <ImBooks />
        </div>
        <h2>Book & Library</h2>
        <p>
          Even the all-powerful Pointing has no control about the blind texts it
          is an almost unorthographic.
        </p>
      </div>

      <div className="single-card odd">
        <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
          <GiSportMedal />
        </div>
        <h2>Sport Clubs</h2>
        <p>
          Even the all-powerful Pointing has no control about the blind texts it
          is an almost unorthographic.
        </p>
      </div>

      <div className="single-card even">
        <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
          <RiUserHeartFill />
        </div>
        <h2>Special Education</h2>
        <p>
          Even the all-powerful Pointing has no control about the blind texts it
          is an almost unorthographic.
        </p>
      </div>
    </div>
  );
}

export default Features;
