import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "../../Misc/Modal";
import { GrDocumentUpdate } from "react-icons/gr";

function ViewCourse() {
  const [inputFields, setInputFields] = useState([]);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const getlocalStudent = JSON.parse(localStorage.getItem("student"));

  // fetch course
  const fetchCourse = async () => {
    const response = await axios.get(
      `http://localhost:5000/api/courses/${getlocalStudent.id}`
    );
    setInputFields(response.data.course);
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  // handle input
  const handleInput = (e, index) => {
    let newEntry = [...inputFields];
    newEntry[index][e.target.name] = e.target.value;
    setInputFields(newEntry);
    setDisableUpdateBtn(false);
  };

  // Submit Edited courses
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowModal(false);

    const editedCourse = {
      studentId: getlocalStudent.id,
      course: inputFields,
    };

    const response = await axios.put(
      `http://localhost:5000/api/course/edit/${getlocalStudent.id}`,
      editedCourse
    );
    console.log(response);
    setDisableUpdateBtn(true);

    // reseting  modal states
    setModalText(response.data.msg);
    setShowModal(true);
  };

  return (
    <div className="view-course">
      {showModal && <Modal modalText={modalText} />}
      <div className="register-content-container">
        <div className="reg-headers">
          <h4>Course Code</h4>
          <h4>Course Title</h4>
          <h4>Course Unit</h4>
        </div>
        <div className="reg-form-container">
          <form onSubmit={handleSubmit}>
            {inputFields.map((input, index) => {
              return (
                <div
                  className="input-field-row-container"
                  style={{ marginBottom: "2em" }}
                  key={index}
                >
                  <span className="course-row-number">{index + 1}.</span>

                  <input
                    type="text"
                    name="courseCode"
                    value={input.courseCode}
                    placeholder="course code"
                    style={{ border: "none", borderBottom: "2px solid #333" }}
                    onChange={(e) => handleInput(e, index)}
                  />

                  <input
                    type="text"
                    name="courseTitle"
                    value={input.courseTitle}
                    placeholder="course title"
                    style={{ border: "none", borderBottom: "2px solid #333" }}
                    onChange={(e) => handleInput(e, index)}
                  />

                  <input
                    type="text"
                    name="courseUnit"
                    value={input.courseUnit}
                    placeholder="course unit"
                    style={{ border: "none", borderBottom: "2px solid #333" }}
                    onChange={(e) => handleInput(e, index)}
                  />
                </div>
              );
            })}

            <button className="submit-course-btn" disabled={disableUpdateBtn}>
              Submit Updated Course
              {
                <GrDocumentUpdate
                  style={{ fontSize: "1.5rem", marginLeft: ".5em" }}
                />
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ViewCourse;
