import React from "react";

function Misc() {
    let comp
  function gen() {
    // alert("ke");
    comp = 
        <div>
            <h1>Hello</h1>
        </div>
    
  
  }

  return (
    <div style={{ marginTop: 70 }}>
        {comp}
      <button onClick={gen}>Click</button>
    </div>
  );
}

export default Misc;
