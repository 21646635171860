const initialData = {
  login: localStorage.getItem("student") ? true : false,
};

function Reducer(state = initialData, action) {
  switch (action.type) {
    case "login":
      return {
        login: true,
      };
    case "logout":
      return {
        login: false,
      };
    default: {
      return state;
    }
  }
}

export default Reducer;
