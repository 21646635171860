import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoutes({ children }) {
  const user = localStorage.getItem("student");
  if (user !== "" && user) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export default ProtectedRoutes;
