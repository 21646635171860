import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// ------Dynamically changing page title property------
let state = false

setInterval(()=>{
  state ? state = false : state = true
  changeTitle()
}, 1000)

function changeTitle(){
  document.title = state ? "School Student Portal" : "EduLight College";
}
// ------Dynamically changing page title property------



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
