import React from 'react'

function Banner() {
  return (
    <div className="home-banner">
      <div className="banner-text">
        <h1>EDUCATION NEEDS COMPLETE SOLUTION</h1>
        <p>
          Learn as much as you can while you are young, since life becomes too
          busy later.
        </p>
        <button>Contact us</button>
      </div>
    </div>
  );
}

export default Banner