import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr";

function Modal({ modalHeaderMsg, modalText, showLogin }) {

  function closeModal(e) {
    if (e.target.className === "modal-container") {
      e.preventDefault();
      e.target.style.display = "none";
    }
  }

  return (
    <div className="modal-container" onClick={closeModal}>
      <div className="modal-wrapper">
        <h1 className="modal-header">{modalHeaderMsg}</h1>
        <p className="modal-text">{modalText}</p>
        {/* <button type="button" className="modal-close-btn" onClick={closeModal}>
          <GrClose />
        </button> */}
        {showLogin && (
          <button className="goto-login">
            <Link to="/login">Go to Login</Link>
          </button>
        )}
      </div>
    </div>
  );
}

export default Modal;
