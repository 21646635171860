import axios from 'axios'
import React, { useState } from 'react'
import { BiPaperPlane } from "react-icons/bi";
import {GrTableAdd} from "react-icons/gr"
import Modal from '../../Misc/Modal'

function RegisterCourse() {
  const [inputFields, setInputFields] = useState([
    {courseCode:"", courseTitle:"", courseUnit:""}
  ]) 
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState("")

  // handle input
  const handleInput=(e, index)=>{
      let newEntry = [...inputFields]
      newEntry[index][e.target.name] = e.target.value
      setInputFields(newEntry)
  }

  // add new field
  const addNewField=()=>{
    let newField = { courseCode: "", courseTitle: "", courseUnit: "" };
    setInputFields([...inputFields, newField])
  }

  // delete Course Field
  const deleteCourseField=(index)=>{
    let courses = [...inputFields]
    courses.splice(index, 1)
    setInputFields(courses)
  }

  // submit courses
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setShowModal(false)

    const getlocalStudent = JSON.parse(localStorage.getItem("student"))

    const addCourse = {
      studentId : getlocalStudent.id,
      course: inputFields
    }

    const response = await axios.post(
      "http://localhost:5000/api/courses/register/", addCourse
    );

    // reseting  modal states
    setModalText(response.data.msg)
    setShowModal(true)
  }

  return (
    <div className="register-course">
      {showModal && <Modal modalText={modalText} />}
      <div className="register-content-container">
        <div className="reg-headers">
          <h4>Course Code</h4>
          <h4>Course Title</h4>
          <h4>Course Unit</h4>
        </div>
        <div className="reg-form-container">
          <form onSubmit={handleSubmit}>
            {inputFields.map((input, index) => {
              return (
                <div className="input-field-row-container" key={index}>
                  <span className="course-row-number">{index + 1}</span>

                  <input
                    type="text"
                    name="courseCode"
                    value={input.courseCode}
                    placeholder="course code"
                    onChange={(e) => handleInput(e, index)}
                  />

                  <input
                    type="text"
                    name="courseTitle"
                    value={input.courseTitle}
                    placeholder="course title"
                    onChange={(e) => handleInput(e, index)}
                  />

                  <input
                    type="text"
                    name="courseUnit"
                    value={input.courseUnit}
                    placeholder="course unit"
                    onChange={(e) => handleInput(e, index)}
                  />

                  <span
                    className="delete-course-row-btn"
                    onClick={() => deleteCourseField(index)}
                  >
                    X
                  </span>
                </div>
              );
            })}
            <button
              className="add-new-course-btn"
              type="button"
              onClick={addNewField}
            >
              Add New Field {<GrTableAdd style={{fontSize:"1.5rem", marginLeft:"1.5em"}}/>}
            </button>

            <button className="submit-course-btn">
              Submit Course{" "}
              {
                <BiPaperPlane
                  style={{ fontSize: "1.5rem", marginLeft: ".5em" }}
                />
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterCourse