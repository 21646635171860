import React, { useState } from "react";
import Modal from "../../Misc/Modal";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Register() {
  // const [fullName, setFullName] = useState("");
  // const [address, setAddress] = useState("");
  // const [state, setState] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNo, setPhoneNo] = useState("");
  // const [password, setPassword] = useState("");

  const [student, setStudent] = useState({
    fullName: "",
    address: "",
    state: "",
    email: "",
    phoneNo: "",
    password: "",
  });

  const { fullName, address, state, email, phoneNo, password } = student;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({ text: "", color: "red" });

  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  // handleInput
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setStudent({ ...student, [key]: value});
  };

  // handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (
      fullName === "" ||
      address === "" ||
      state === "" ||
      email === "" ||
      phoneNo === "" ||
      password === ""
    ) {
      setError({ text: "*Fields cannot be empty", color: "red" });
    } else {
      setStudent({
        fullName,
        address,
        state,
        email,
        phoneNo,
        password,
      });

      const response = await axios.post(
        "http://localhost:5000/api/students/register",
        student
      );
      console.log(response);
      if (response.data.msg !== "Student added successfully") {
        setError({ text: response.data.msg, color: "red" });
      } else {
        setError({ text: response.data.msg, color: "green" });
        setStudent(response.data.addStudent);
        console.log(student);
        setShowModal(true);
      }
    }
  };

  // Hide/Show Password
  const showPasswordFunc = () => {
    if (password.length > 0) {
      setShowPassword(!showPassword);
      showPassword ? setPasswordType("password") : setPasswordType("text");
    }
  };

  return (
    <div className="register">
      {showModal && (
        <Modal
          modalHeaderMsg={"MATRIC NO"}
          modalText={student.matricNo}
          showLogin={true}
        />
      )}
      <div className="container">
        <h1 className="register-header">REGISTER</h1>
        <h5 className="error-msg" style={{ color: error.color }}>
          {error.text}
        </h5>
        <form className="reg-form" onSubmit={handleSubmit}>
          <div className="form-inputs-container">
            <div className="inputs-bank-1">
              <div>
                <label>Full Name</label>
                <input name="fullName" type="text" onChange={handleInput} />
              </div>

              <div>
                <label>Address</label>
                <input name="address" type="text" onChange={handleInput} />
              </div>

              <div>
                <label>State</label>
                <input name="state" type="text" onChange={handleInput} />
              </div>
            </div>
            <div className="inputs-bank-2">
              <div>
                <label>Email</label>
                <input name="email" type="text" onChange={handleInput} />
              </div>

              <div>
                <label>Phone No</label>
                <input name="phoneNo" type="text" onChange={handleInput} />
              </div>

              <div className="reg-password">
                <label>Password</label>
                <input
                  name="password"
                  type={passwordType}
                  onChange={handleInput}
                />
                <p onClick={showPasswordFunc}>
                  {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                </p>
              </div>
            </div>
          </div>
          <button className=" submit-btn" type="submit">
            submit
          </button>
        </form>
        <div className="reg-text">
          <p>
            Already have an account ?
            <button>
              <Link to="/login">Click here</Link>
            </button>
            to login
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
